var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Mandates ")]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.customersList,"item-text":"value","item-value":"key","placeholder":"Customer","multiple":"","dense":"","chips":"","clearable":""},on:{"change":_vm.getData},model:{value:(_vm.filters.customerIds),callback:function ($$v) {_vm.$set(_vm.filters, "customerIds", $$v)},expression:"filters.customerIds"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-select',{attrs:{"items":['Pending Approval', 'Active', 'In Active'],"placeholder":"Mandate Status","multiple":"","dense":"","chips":"","clearable":""},on:{"change":_vm.getData},model:{value:(_vm.filters.statuses),callback:function ($$v) {_vm.$set(_vm.filters, "statuses", $$v)},expression:"filters.statuses"}})],1)],1)],1),_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.mandatesList,"item-key":"full_name","dense":"","loading":_vm.loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("format_utc_date_local_timezone")(item.startDate))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("format_date_local_timezone")(item.endDate))+" ")]}},{key:"item.maxAmount.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("format_money")(item.maxAmount.amount,item.maxAmount.currency))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.status == 'Completed'
              ? 'success'
              : item.status == 'Aborted'
              ? 'error'
              : item.status == 'Partially Complete'
              ? 'warning'
              : ''}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.bankCode",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.bankCode ? ((item.bankCode) + "," + (item.authType) + " (" + (item.method) + ")") : '')+" ")]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-btn-toggle',[_c('v-btn',{attrs:{"elevation":"8","disabled":item.isDefault,"x-small":"","title":"Set as a default"},on:{"click":function($event){return _vm.defaultMandateConfirmation(item.id)}}},[_vm._v(" Set as a default ")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }