<template>
  <v-row>
    <v-col cols="12">
      <AddMandateForm @mandateAdded="mandateAdded" />
    </v-col>
    <v-col cols="12">
      <datatable :key="mandateDtKey"></datatable>
    </v-col>
  </v-row>
</template>

<script>
import Datatable from './Datatable'
import AddMandateForm from './SendMandateRegistration'
import { ref, reactive } from '@vue/composition-api'
import Util from '@/utils/axios-util.js'
import { config } from '@/constants/config.js'
import moment from 'moment'

export default {
  components: {
    Datatable,
    AddMandateForm,
  },
  setup() {
    const mandateDtKey = ''
    const sellerInvoiceNo = ''
    let editObj = reactive({})
    const editComponent = ref('')

    return {
      mandateDtKey,
      sellerInvoiceNo,
      editObj,
      editComponent,
    }
  },
  methods: {
    mandateAdded: function() {
      let e = this
      e.mandateDtKey = e.GenerateRandomKey(4)
    },
  },
}
</script>
